/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


input {
    @apply p-1.5 w-full;
}

select {
    @apply p-1.5 w-full;
}

.btn {
    @apply p-1.5;
}

input[type="submit"],
input[type="button"] {
    cursor: pointer;
}

input:disabled {
    cursor: none;
}

.login-form {
    transform: translate(-50%, -50%)
}

.balance-item .item-actions {
    display: none;
}

.balance-item:hover .item-actions,
.balance-item:focus .item-actions
{
    display: block; 
}
